<template>
  <div class="main-setting-wrapper">
    <div class="setting-header">
      <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
      <div class="title">Profile</div>
      <ActionButton
        v-if="!isLoadingSettings"
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Save'"
        :class="{'action-btn-not-active': isEnterSettingValue}"
        @click="saveSettings"
      />
      <div class="action-btn loading-indicator-container" v-if='isLoadingSettings'>
        <div class="spinner"></div>
      </div>
    </div>
    <div class="user-profile">
      <div class="error-container">{{userDataErrorMessage}}</div>
      <div class="common-field-container">
        <div class="common-lable">Email</div>
        <div class="common-field">{{currentUser.email}}</div>
      </div>
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Full Name'"
        :placeholder="'John Doe'"
        :value='newUserFullName'
        @updateValue="setNewUserName($event)"
      />
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Phone'"
        :placeholder="'+1(050)124-214-12'"
        :value='newUserPhone'
        @updateValue="setNewUserPhone($event)"
      />
    </div>
    <div class="change-password-header">
      <div class="title-password">Change Password</div>
      <ActionButton
        v-if="!isLoading"
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Save'"
        :class="{'action-btn-not-active': isEnterPasswordValue}"
        @click="changePassword"
      />
      <div class="action-btn loading-indicator-container" v-if='isLoading'>
        <div class="spinner"></div>
      </div>
    </div>
    <div class="password-wrapper">
      <div class="error-container">{{passwordErrorMessage}}</div>
      <!-- Old Password field -->
      <div class="input-container password-container">
        <div class="field-label">Old Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="userPassword"
        >
        <div
          class="password-visibility-btn"
          :class="{
          'password-visible-icon': passwordFieldType === 'password',
          'password-invisible-icon': passwordFieldType === 'text',
        }"
          @click="switchPasswordVisibility"
        >
        </div>
      </div>
      <!-- New Password field -->
      <div class="input-container password-container">
        <div class="field-label">New Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="newUserPassword"
        >
        <div
          class="password-visibility-btn"
          :class="{
          'password-visible-icon': passwordFieldType === 'password',
          'password-invisible-icon': passwordFieldType === 'text',
        }"
          @click="switchPasswordVisibility"
        >
        </div>
      </div>
      <!-- Re-enter Password field -->
      <div class="input-container password-container">
        <div class="field-label">Confirm New Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="reenterUserPassword"
        >
        <div
          class="password-visibility-btn"
          :class="{
          'password-visible-icon': passwordFieldType === 'password',
          'password-invisible-icon': passwordFieldType === 'text',
        }"
          @click="switchPasswordVisibility"
        >
        </div>
      </div>
    </div>
    <div class="logout-wrapper">
      <ActionButton
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Logout'"
        :class="{'logout-btn': true}"
        @click="LogoutAdmin"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CommonInputField from '../../common/CommonInputField.vue';
import ActionButton from '../../common/ActionButton.vue';
import { UserRole } from '../../../utils/constants';


export default {
  components: {
    CommonInputField,
    ActionButton,
  },
  data() {
    return {
      passwordFieldTypes: {
        PASSWORD: 'password',
        TEXT: 'text',
      },
      passwordFieldType: '',
      userDataErrorMessage: '',
      passwordErrorMessage: '',
      isLoadingSettings: false,
      isLoading: false,
      errorState: false,
      newUserFullName: '',
      newUserEmail: '',
      newUserPhone: '',
      userPassword: '',
      newUserPassword: '',
      reenterUserPassword: '',
      successMessage: '',
      isEnterSettingValue: false,
      isEnterPasswordValue: false,
    }
  },
  created() {
    this.setInitialValue()
  },
  watch: {
    successMessage(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {this.successMessage = ''},2000)
      }
    },
    reenterUserPassword(newValue, oldValue) {
      if(newValue) this.isEnterPasswordValue = true
      if(!newValue) this.isEnterPasswordValue = false
    }
  },
  computed: {
    ...mapState([
      'currentUser',
    ]),
  },
  methods: {
    ...mapActions([
      'signOut',
      'updateUser',
      'changeUserPassword',
    ]),
    setInitialValue() {
      this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      this.newUserFullName = this.currentUser.full_name
      this.newUserPhone = this.currentUser.phone
    },
    async LogoutAdmin() {
      await this.signOut()
      this.$router.push({ name: 'SiginAdmin' })
    },
    async changePassword () {
      if (!this.userPassword || !this.newUserPassword || !this.reenterUserPassword) {
        this.passwordErrorMessage = 'Please, fill password fields'
        return
      }

      if (this.newUserPassword !== this.reenterUserPassword) {
        this.passwordErrorMessage = 'The passwords should be equal and should be at least 8 characters long.'
        return
      }

      const passwords = {
        old_password: this.userPassword,
        new_password: this.newUserPassword,
      }

      this.isLoading = true

      try {
        this.passwordErrorMessage = ''
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD

        await this.changeUserPassword(passwords)
      } catch (error) {
        this.passwordErrorMessage = error.response.data.detail
      } finally {
        this.isLoading = false
        this.isEnterPasswordValue = false
      }
    },
    async saveSettings() {
      if (!this.newUserFullName ) {
        return
      }

      if (this.newUserFullName === this.currentUser.full_name && this.newUserPhone === this.currentUser.phone) {
        return
      }

      const updated_user = {
        id: this.currentUser.id,
        full_name: this.newUserFullName,
        phone: this.newUserPhone,
      }
      const user = {
        email: this.currentUser.email,
        phone: this.newUserPhone,
        full_name: this.newUserFullName,
        id: this.currentUser.id,
        role: UserRole.Admin,
      }

      this.isLoadingSettings = true
      this.userDataErrorMessage = ''

      try {
        await this.updateUser(updated_user)
        sessionStorage.setItem('currentUser', JSON.stringify(user))
      } catch (error) {
        this.userDataErrorMessage = 'Something went wrong. Please try again'
      } finally {
        this.isLoadingSettings = false
        this.isEnterSettingValue = false
      }
    },
    switchPasswordVisibility(){
      if (this.passwordFieldType === this.passwordFieldTypes.PASSWORD) {
        this.passwordFieldType = this.passwordFieldTypes.TEXT
      } else {
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      }
    },
    setNewUserName(newUserFullName) {
      this.isEnterSettingValue = true
      this.newUserFullName = newUserFullName
    },
    setNewUserPhone(newUserPhone) {
      this.isEnterSettingValue = true
      this.newUserPhone = newUserPhone
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.main-setting-wrapper {
  width: 720px;
  height: 760px;
  margin-top: 30px;
  padding-top: 30px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 4px;
  background-size: 24px 24px;

  .action-button {
    margin-top: 0;
  }

  .error-container {
    width: 100%;
    height: 24px;
    min-height: 24px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    color: $redWarningColor;
  }

  .error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
    z-index: 999999 !important;
    position: fixed !important;
    right: 26px;
    left: 26px;
    top: 96px;
    padding: 16px;
    border-radius: 2px;
  }

  .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
    z-index: 999999 !important;
    position: fixed !important;
    right: 26px;
    left: 26px;
    top: 96px;
    padding: 16px;
    border-radius: 2px;
  }

  .change-password-header {
    width: 90%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;

    .action-button {
      background-color: $grayBorderColor;
      color: $fontColorBlack;
    }

    .action-btn {
      width:151px;
      height: 45px;
      margin-top:20px;
      min-height: 45px;
      display: flex;
      justify-content: center;
      background-color: $grayBorderColor;
      color: $fontColorBlack;
      cursor: pointer;
    }

    .action-btn-not-active {
      background-color: $appActionColor;
    }

    .loading-indicator-container {
      cursor: wait;

      &:hover {
        background-color: $grayBorderColor;
      }

      .spinner {
        margin-top: 10px;
        border: 5px solid $whiteColor;
        border-radius: 50%;
        border-top: 5px solid $appActionColor;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }

  .setting-header {
    width: 90%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $fontColorBlack;
    margin-left: 24px;

    .sidebar-btn {
      width: 0;
      height: 0;
      position: absolute;
      background-size: 24px;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/hamburger-icon.svg');
    }

    .action-button {
      background-color:$grayBorderColor;
      color:$fontColorBlack;
    }

    .action-btn-not-active {
      background-color: $appActionColor
    }

    .action-btn {
      width:151px;
      height: 45px;
      margin-top:20px;
      min-height: 45px;
      display: flex;
      justify-content: center;
      background-color: $grayBorderColor;
      color: $fontColorBlack;
      cursor: pointer;
    }

    .loading-indicator-container {
      cursor: wait;

      &:hover {
        background-color: $grayBorderColor;
      }

      .spinner {
        margin-top: 10px;
        border: 5px solid $whiteColor;
        border-radius: 50%;
        border-top: 5px solid $appActionColor;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size:$fontSizeModalTitle;
    }
  }

  .user-profile {
    width: calc(100% - 50px);
    height: 248px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    position: relative;
    margin-bottom: 12px;

    .common-input-lable {
      height: 24px;
      font-size: $fontSizeStandart;
    }

    .common-field-container {
      width: 320px;
      height: 54px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content:center;

      .common-lable {
        height: 24px;
        font-size:$fontSizeMinified;
      }

      .common-field {
        width: 100%;
        padding:4px;
        font-size:$fontSizeStandart;
      }
    }
  }

  .title-password {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    font-size:$fontSizeModalTitle;
  }

  .password-wrapper {
    width: calc(100% - 80px);
    height: 248px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .field-label {
      color:$fontColorBlack;
      font-size:$fontSizeStandart;
    }

    .input-field {
      width: 320px;
      height: 44px;
      margin-top:8px;
      padding: 0 6px;
      font-size: $fontSizeStandart;
      border: 1px solid $grayBorderColor;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .input-container {
      width: 320px;
    }

    & > .input-container {
      width: 320px;
    }

    & > .input-container ~ .input-container {
      margin-top: 20px;
    }

    .password-container {
      position: relative;

      .password-visibility-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(100% - 34px);
        right: 12px;
        height: 24px;
        background-size: 24px;
        background-position: center;
        cursor: pointer;
      }

      .password-invisible-icon {
        background-image: url('../../../assets/icons/visibility-off-icon.svg');
      }

      .password-visible-icon {
        background-image: url('../../../assets/icons/visibility-on-icon.svg');
      }
    }
  }

  .logout-wrapper {
    display: flex;
    align-items: flex-end;
    margin-left: 24px;
    margin-top: 50px;
    margin-bottom: 12px;
    height: auto;

    .logout-btn {
      background-color: $redWarningColor;

      &:hover {
        background-color: $hoveredRedWarninngColor;
      }
    }
  }
}

@media (max-width: 1024px) {
  .sidebar-btn {
    width: 24px !important;
    height: 24px !important;
  }
}

@media (max-width: 420px) {
  .main-setting-wrapper{
    height: 1024px !important;
    width: 366px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .error-message {
    margin-top: 75px;
    margin-left: 45px;
  }
  .success-message {
    margin-top: 75px;
    margin-left: 45px;
  }
  .main-contents {
    width: 365px !important;
    margin-left: 12px;
    overflow-y: scroll !important;
  }
  .user-profile {
    margin-top: 0px;
  }
  .password-wrapper {
    margin-top: 0px;
  }
}
</style>
